/* eslint-disable */
(window as any)['__zone_symbol__PASSIVE_EVENTS'] = ['scroll', 'touchstart', 'touchmove'];

(window as any)['__Zone_ignore_on_properties'] = [];

// disable on properties
// tslint:disable-next-line: typedef
const targets = [WebSocket.prototype];
targets.forEach((target) => {
  (window as any)['__Zone_ignore_on_properties'].push({
    target,
    ignoreProperties: ['close', 'error', 'open', 'message']
  });
});

// disable addEventListener
(window as any)['__zone_symbol__BLACK_LISTED_EVENTS'] = ['message'];

